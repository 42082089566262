import { createApp } from 'vue';
import { sendRankingReport } from './common/report';

if (document.body.id === "subscribers-ranking") {
    const app = createApp({
        delimiters: ['[[', ']]'],
        methods: {
            reportClick(event: MouseEvent) {
                const target = event.target as HTMLElement;
                const utaiteCard = target.closest('.utaite-card');
                this.rankingName = "歌い手";
                this.rankerName = utaiteCard?.querySelector('.card-title')?.innerHTML;
            },
            reportModalClick(event: MouseEvent) {
                sendRankingReport(event, this.rankingName, this.rankerName);
            }
        },
    });
    app.mount('#subscribers-ranking');
}