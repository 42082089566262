import $ from 'jquery';

// TODO Vue.jsを使っているので、jQueryを使わないようにする、たぶんVueのとこでは動いてない
// 画像の読み込みに失敗したら、サーバーに通知する
$('img').on('error', function () {
    $(this).parent().append('<div class="hot-loader" style="width:100%;height:100%"></div>');
    $(this).remove();
    const eiFd = new FormData();
    let csrfToken = $('meta[name="csrf-token"]').attr('content') as string;
    eiFd.set('csrfmiddlewaretoken', csrfToken);
    let src = $(this).attr('src') as string;
    eiFd.set('src', src);
    var errorImgCheckUrl = $('meta[name="error-img-check-url"]').attr('content') as string;
    fetch(errorImgCheckUrl, {
        method: "POST",
        body: eiFd,
    });
});

// bootstrapのtooltipを使う
import * as bootstrap from 'bootstrap';
const tooltipTriggerList = Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
const tooltipList = tooltipTriggerList.map((tooltipTriggerEl: Element) => {
    return new bootstrap.Tooltip(tooltipTriggerEl)
});

$('#right-menu button').on("click", function () {
    // nav-searchのdisplayをblockに変更
    $('#nav-search').slideDown(200);
});

$('.hide-search').on("click", function () {
    // nav-searchのdisplayをblockに変更
    $('#nav-search').slideUp(100);
});