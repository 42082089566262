import * as bootstrap from 'bootstrap';

export function int2kanji(value: number) {
    if (value == undefined || value == null) {
        return ''
    }

    let res = ''
    const OKU = 100000000
    const MAN = 10000
    if (value >= OKU) {
        res = `${Math.floor(value / OKU)}億`
    }
    value %= OKU
    if (value >= MAN) {
        return res + `${Math.floor(value / MAN)}万`
    }

    return value
}

export function alertModal(title: string, message: string) {
    // モーダルを取得
    const modalElement = document.getElementById('alert');
    const modalTitleElement = modalElement?.querySelector('.modal-title');
    if (modalTitleElement) {
        modalTitleElement.innerHTML = title;
    }
    const modalBodyElement = modalElement?.querySelector('.modal-body');
    if (modalBodyElement) {
        modalBodyElement.innerHTML = message;
    }

    modalElement?.addEventListener('hidden.bs.modal', function () {
        if (modalTitleElement) {
            modalTitleElement.innerHTML = "";
        }
        if (modalBodyElement) {
            modalBodyElement.innerHTML = "";
        }
    });

    // モーダルが存在するかチェック
    if (modalElement) {
        // モーダルのインスタンスを作成
        const myModal = new bootstrap.Modal(modalElement, {
            backdrop: true, // オプション: trueだと背景クリックで閉じる
            keyboard: true  // オプション: trueだとESCキーで閉じる
        });

        // 任意のタイミングでモーダルを表示
        myModal.show();
    }
}
